.carousel.carousel-slider {
  overflow: inherit;
}

.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
  background-color: transparent;
  right: -28px;
}

.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
  background-color: transparent;
  left: -28px;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}

.carousel .control-next.control-arrow:before {
  content: '';
  border: solid #8c8c8c;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
  content: '';
  border: solid #8c8c8c;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.carousel .control-dots .dot {
  background: #000000;
}
