@import '~antd/dist/antd.compact.less';

html {
  --scrollbar_bg: transparent;
  --thumb_bg: #bfbfbf;
}
body,
.ant-statistic-content-value {
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Personaliza a barra de scroll para todo o layout */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumb_bg) var(--scrollbar_bg);
}
*::-webkit-scrollbar-track {
  background: var(--scrollbar_bg);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumb_bg);
  border-radius: 6px;
  /* border: 3px solid var(--scrollbar_bg); */
}

html,
body,
#root {
  height: 100vh;
}

b {
  font-weight: 500;
}

.ant-layout {
  min-height: 100vh;
}
/* Ajuste para o visual do form de login */
.ant-input-prefix {
  margin: auto 8px auto 0;
}
/* O estilo para essa media query estava com o valor "text-bottom", mas estava quebrando o layout no Firefox */
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .ant-radio {
    vertical-align: sub;
  }
}
/* O item selecionado nos selects estava aparecendo com "...", mesmo com espaço sobrando */
.ant-select-single .ant-select-selection-item {
  width: 100%;
}
/* Forms verticais estavam com muito espaço entre o label e field */
.ant-form-vertical .ant-form-item-label {
  padding-bottom: 0 !important;
}
/* A margem também estava grande (16px) */
.ant-form-vertical .ant-form-item {
  margin-bottom: 8px !important;
}
.ant-page-header-heading-title {
  font-weight: 500;
}
.upperCase {
  text-transform: uppercase !important;
}
.upperCase > * {
  text-transform: uppercase !important;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}

/* @media (max-width: @screen-xs) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
    &-thead > tr,
    &-tbody > tr {
      > th,
      > td {
        white-space: pre;
        > span {
          display: block;
        }
      }
    }
  }
} */

@black: #000;@white: #fff;@gray_1: @white;@gray_2: #fafafa;@gray_3: #f5f5f5;@gray_4: #f0f0f0;@text-color: fade(@black, 100%);@text-color-secondary: fade(@black, 70%);@heading-color: fade(@black, 100%);@text-color-dark: fade(@white, 100%);@text-color-secondary-dark: fade(@white, 90%);@body-background: @gray_2;@component-background: #fcfcfc;@background-color-light: @gray_3;@background-color-base: @gray_4;@layout-sider-background-light: @gray_2;@layout-trigger-background-light: @gray_2;@font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@select-item-selected-font-weight: 500;@typography-title-font-weight: 500;